var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('div',{staticClass:"text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center"},[_vm._v(" Solicitud de material ")]),_c('v-btn',{staticClass:"mb-2",attrs:{"to":"/solicitud-material-nuevo","color":"primary"}},[_vm._v(" Nueva solicitud ")]),_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"overflow":"scroll"},attrs:{"headers":_vm.headers,"items":_vm.dataRows,"options":_vm.options,"footer-props":{itemsPerPageOptions: [15, 30, 50, 100]},"server-items-length":_vm.totalDataRows,"loading":_vm.loading,"mobile-breakpoint":"0","dense":""},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.onClickRowEditarEstado},scopedSlots:_vm._u([{key:"item.SolFec",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.SolFec))+" ")]}},{key:"item.SolStaFec",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.SolStaFec))+" ")]}},{key:"item.SolStaNom",fn:function(ref){
var item = ref.item;
return [(item.edit)?_c('div',{staticStyle:{"width":"260px"}},[_c('SolStaFind',{ref:"solStaCtrl",staticClass:"mt-2 mb-n5 mr-2",attrs:{"label":"Estado","minSolSta":item.SolStaId,"maxSolSta":109,"readonly":item.loading},on:{"change":function($event){return _vm.onChangeSelectEstado(item)}},model:{value:(item.newSolSta),callback:function ($$v) {_vm.$set(item, "newSolSta", $$v)},expression:"item.newSolSta"}})],1):_c('v-chip',{staticClass:"status-chip",staticStyle:{"min-width":"85px"},attrs:{"color":_vm.statusColor(item.SolStaId),"small":""}},[_vm._v(" "+_vm._s(item.SolStaNom)+" ")])]}},{key:"item.Opt",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2 my-1",staticStyle:{"width":"30px","min-width":"30px"},attrs:{"to":'/solicitud-material-detalle/' + item.SolId,"small":""}},[_vm._v(" ... ")]),(item.SolStaId == _vm.statusRefs.Pendiente || item.SolStaId == _vm.statusRefs.Devuelto)?_c('v-btn',{staticClass:"mr-2 my-1",staticStyle:{"width":"75px"},attrs:{"to":'/solicitud-material-editar/' + item.SolId,"color":"primary","small":""}},[_vm._v(" Editar ")]):_vm._e(),(item.SolStaId == _vm.statusRefs.Pendiente || item.SolStaId == _vm.statusRefs.Devuelto)?_c('v-btn',{staticClass:"mr-2 my-1",staticStyle:{"width":"75px"},attrs:{"color":"error","small":""},on:{"click":function($event){return _vm.onBtnShowDeleteModal(item)}}},[_vm._v(" Borrar ")]):_vm._e()]}}])})],1),_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","persistent":"","width":"500"},model:{value:(_vm.showDeleteDialog),callback:function ($$v) {_vm.showDeleteDialog=$$v},expression:"showDeleteDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" BORRAR SOLICITUD ")]),_c('v-card-text',{staticClass:"mt-2"},[_vm._v(" ¿Desea borrar la solicitud? Esta acción no se podrá deshacer. ")]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"loading":_vm.loading,"color":"primary"},on:{"click":_vm.onBtnDelete}},[_vm._v(" Aceptar ")]),_c('v-btn',{on:{"click":_vm.onBtnCancelDelete}},[_vm._v(" Cancelar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }