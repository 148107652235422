<template>
    <div>
        <v-container>
            <div class="text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center">
                Solicitud de material
            </div>

            <v-btn
                to="/solicitud-material-nuevo"
                color="primary"
                class="mb-2">
                Nueva solicitud
            </v-btn>

            <v-data-table
                :headers="headers"
                :items="dataRows"
                :options.sync="options"
                :footer-props="{itemsPerPageOptions: [15, 30, 50, 100]}"
                :server-items-length="totalDataRows"
                :loading="loading"
                @click:row="onClickRowEditarEstado"
                style="overflow: scroll"
                mobile-breakpoint="0"
                class="elevation-1"
                dense>
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.SolFec="{ item }">
                    {{ formatDate(item.SolFec) }}
                </template>
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.SolStaFec="{ item }">
                    {{ formatDate(item.SolStaFec) }}
                </template>
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.SolStaNom="{item}">
                    <div v-if="item.edit" style="width: 260px">
                        <SolStaFind
                            class="mt-2 mb-n5 mr-2" 
                            ref="solStaCtrl"
                            label="Estado"
                            v-model="item.newSolSta"
                            :minSolSta="item.SolStaId"
                            :maxSolSta="109"
                            :readonly="item.loading"
                            @change="onChangeSelectEstado(item)">
                        </SolStaFind>
                    </div>
                    <v-chip v-else
                        :color="statusColor(item.SolStaId)"
                        small
                        style="min-width:85px"
                        class="status-chip">
                        {{item.SolStaNom}}
                    </v-chip>
                </template>
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.Opt="{ item }">
                    <v-btn
                        :to="'/solicitud-material-detalle/' + item.SolId"
                        class="mr-2 my-1"
                        small
                        style="width:30px; min-width:30px;">
                        ...
                    </v-btn>

                    <v-btn v-if="item.SolStaId == statusRefs.Pendiente || item.SolStaId == statusRefs.Devuelto"
                        :to="'/solicitud-material-editar/' + item.SolId"
                        color="primary"
                        class="mr-2 my-1"
                        small
                        style="width:75px">
                        Editar
                    </v-btn>

                    <v-btn v-if="item.SolStaId == statusRefs.Pendiente || item.SolStaId == statusRefs.Devuelto"
                        color="error"
                        class="mr-2 my-1"
                        small
                        style="width:75px"
                        @click="onBtnShowDeleteModal(item)">
                        Borrar
                    </v-btn>
                </template>
            </v-data-table>
        </v-container>

        <v-dialog
            v-model="showDeleteDialog"
            transition="dialog-bottom-transition"
            persistent
            width="500">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    BORRAR SOLICITUD
                </v-card-title>

                <v-card-text class="mt-2">
                    ¿Desea borrar la solicitud? Esta acción no se podrá deshacer.
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                      :loading="loading"
                      color="primary"
                      @click="onBtnDelete">
                      Aceptar
                  </v-btn>
                  <v-btn @click="onBtnCancelDelete">
                      Cancelar
                  </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import SolStaFind from "../components/SolStaFind.vue";
export default ({
    components: {
        SolStaFind
    },
    computed: {
        ...mapState(['empId', 'perId', 'urlRaiz'])
    },
    data () {
        return {
            loading: true,
            statusRefs: {
              Pendiente: 10,
              Aceptado: 20,
              Rechazado: 30,
              EnTramite: 40,
              Enviado: 50,
              RecibidoConforme: 60,
              RecibidoNoConforme: 70,
              Devuelto: 110,
              DevueltoConforme: 120,
              DevueltoNoConforme: 130
            },
            showDeleteDialog: false,
            selectedItem: null,
            totalDataRows: 0,
            dataRows: [],
            options: {itemsPerPage: 15},
            headers: [
                { text: 'Material', width: 200, sortable: false, value: 'MatNom' },
                { text: '', width: 0, class: "px-1", cellClass: "px-1", sortable: false, value: 'MatVarNom1' },
                { text: '', width: 0, class: "px-1", cellClass: "px-1", sortable: false, value: 'MatVarNom2' },
                { text: 'Fecha solicitud', width: 150, sortable: false, value: 'SolFec' },
                { text: 'Estado', width: 50, sortable: false, value: 'SolStaNom' },
                { text: 'Fecha estado', width: 150, sortable: false, value: 'SolStaFec' },
                { text: '', width: 240, sortable: false, value: 'Opt', align: 'end' },
            ],
            dtOptionsLoaded: false
        }
    },
    watch: {
      options: {
        handler () {
          if(!this.dtOptionsLoaded) {
            this.dtOptionsLoaded = true;
            return;
          }
          this.loadData();
        },
        deep: true
      }
    },
    mounted() {
      this.loadData();
    },
    methods: {
      getDataFromApi() {
        this.loading = true;

        var NumRegsPag = this.options.itemsPerPage;
        var NumPag = this.options.page;

        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'GET_DATA_LIST_PAGINATED',
            NumRegsPag: NumRegsPag,
            NumPag: NumPag,
            Filtros: {
              PerId: this.perId
            }
          };
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/sol", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const items = result.data.EntsList;
            const total = result.data.EntsTotCount;

            setTimeout(() => {
              this.loading = false;
              resolve({
                items,
                total
              })
            }, 1000)
          }).catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al cargar las solicitudes.');
            }
          });
        })
      },
      postSolStaToApi(item) {
        item.loading = true;
        this.$forceUpdate();

        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'CHANGE_SOL_STA',
            SolId: item.SolId,
            SolStaId: item.newSolSta.SolStaId,
            PerId: this.perId
          };
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/sol", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const success = result.data;

            setTimeout(() => {
                item.loading = false;
                resolve({
                  success
                })
            }, 1000)
          }).catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al cambiar el estado.');
            }
          });
        })
      },
      deleteDataFromApi() {
        this.loading = true;
        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'DEL_ENTITY',
            SolId: this.selectedItem.SolId
          };
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/sol", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const success = result.data;

            setTimeout(() => {
                this.loading = false;
                resolve({
                  success
                })
            }, 1000)
          }).catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al borrar la solicitud.');
            }
          });
        })
      },
      loadData() {
        this.getDataFromApi()
        .then(data => {
          this.dataRows = data.items
          this.totalDataRows = data.total
        });
      },
      guardarEstado(item) {
        this.postSolStaToApi(item).then(data => {
          if(data.success == true) {
              item.SolStaId = item.newSolSta.SolStaId;
              item.SolStaNom = item.newSolSta.SolStaNom;
              item.SolStaFec = new Date();
              item.edit = false;
              this.$forceUpdate();
              item.newSolSta = {};
          }
        });
      },
      onClickRowEditarEstado(item) {
        if(item.loading) { return; }
        if(item.edit) { return; }
        if(item.SolStaId !== this.statusRefs.Enviado) { return; }
        if(this.selectedItem != null) {
          const actualIndex = this.dataRows.map(m => m.SolId).indexOf(this.selectedItem);
          if(actualIndex != -1) {
            this.dataRows[actualIndex].edit = false;
            this.dataRows[actualIndex].newSolSta = {};
          }
        }
        
        this.selectedItem = item.SolId;

        if(item.edit) { return; }
        item.edit = true;
        item.loading = false;
        item.newSolSta = {
          SolStaId: item.SolStaId,
          SolStaNom: item.SolStaNom
        };
        this.$forceUpdate();
      },
      onChangeSelectEstado(item) {
        if(item.newSolSta == null) { return; }
        if(item.SolStaId == item.newSolSta.SolStaId) { return; }
        this.guardarEstado(item);
      },
      onBtnDelete() {
        this.deleteDataFromApi().then(data => {
          if (data.success) {
            this.selectedItem = null;
            this.showDeleteDialog = false;
            this.loadData();
          }
        });
      },
      onBtnShowDeleteModal(item) {
        this.selectedItem = item;
        this.showDeleteDialog = true;
      },
      onBtnCancelDelete() {
        this.selectedItem = null;
        this.showDeleteDialog = false;
      },
      statusColor(status) {
        if(status === this.statusRefs.Aceptado) { return 'green'; }
        if(status === this.statusRefs.Rechazado) { return 'red'; }
        if(status === this.statusRefs.EnTramite) { return 'green'; }
        if(status === this.statusRefs.Enviado) { return 'green'; }
        if(status === this.statusRefs.RecibidoConforme) { return 'green'; }
        if(status === this.statusRefs.RecibidoNoConforme) { return 'yellow'; }
        if(status === this.statusRefs.DevueltoConforme) { return 'green'; }
        if(status === this.statusRefs.DevueltoNoConforme) { return 'red'; }
        return '';
      },
      formatDate (value) {
        const date = new Date(value);
        return String(date.getDate()).padStart(2, '0') + '/' + 
            String(date.getMonth() + 1).padStart(2, '0')  + '/' + 
            date.getFullYear() + ' ' +
            String(date.getHours()).padStart(2, '0') + ':' + 
            String(date.getMinutes()).padStart(2, '0');
      },
    } 
})
</script>

<style>
  .status-chip .v-chip__content {
    margin: auto;
  }
</style>
